import { graphql } from 'gatsby';
import { StructuredText } from 'react-datocms';

import { PageWrapper } from 'components/Layout/PageWrapper';
import { HorizontalContainer } from 'components/UI/Containers';
import { HeadingBig } from 'components/UI/Headings';

const ReportsPageTemplate = ({ data, pageContext }) => {
  const { datoCmsReportsPage: pageData } = data;
  const { title, seo, reportsDescription } = pageData;

  return (
    <PageWrapper
      pageData={pageContext}
      seoTitle={seo?.seoTitle}
      seoDescription={seo?.seoDescription}
      seoImage={seo?.seoImage?.seoImageUrl}
    >
      <HorizontalContainer>
        <HeadingBig>{title}</HeadingBig>
        <section>
          <StructuredText data={reportsDescription.value} />
        </section>
      </HorizontalContainer>
    </PageWrapper>
  );
};

export default ReportsPageTemplate;

export const query = graphql`
  query ReportsPageQuery($locale: String!) {
    datoCmsReportsPage(locale: { eq: $locale }) {
      locale
      seo {
        seoTitle: title
        seoDescription: description
        seoImage: image {
          seoImageUrl: url
        }
      }
      title
      reportsDescription {
        value
      }
    }
  }
`;
